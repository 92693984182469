import React from 'react';
import  '../styles/home.css';

function Home({
    isMobile
}) {
    return (
        <div className="header">
            <h1>Home</h1>
        </div>
    );
}

export default Home;