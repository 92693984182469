import React from 'react';
import  '../styles/home.css';

function GameFi({
    isMobile
}) {
    return (
        <div className="header">
            <h1>GameFi</h1>
        </div>
    );
}

export default GameFi;