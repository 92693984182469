import React from 'react';
import  '../styles/home.css';

function ICOs({
    isMobile
}) {
    return (
        <div className="header">
            <h1>ICOs</h1>
        </div>
    );
}

export default ICOs;